import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';

import {
  RichText,
  RichTextArticle,
  richTextHasContent,
} from '@italia/components/ItaliaTheme/View';

/* customizzazione:
    - Cambiata traduzione Cos'è in Introduzione
    - Aggiunto campo Come si svolge
*/

const messages = defineMessages({
  cos_e: {
    id: 'service_cos_e',
    defaultMessage: 'Introduzione',
  },
  come_si_svolge: {
    id: 'come_si_svolge',
    defaultMessage: 'Come si svolge',
  },
});

const ServizioCosE = ({ content }) => {
  const intl = useIntl();
  return (
    (content.descrizione_estesa ||
      richTextHasContent(content.come_si_svolge)) && (
      <RichTextArticle
        content={content.descrizione_estesa}
        tag_id="cos_e"
        field="cos_e"
        title={intl.formatMessage(messages.cos_e)}
      >
        <RichText
          title={intl.formatMessage(messages.come_si_svolge)}
          title_size="h5"
          content={content.come_si_svolge}
        />
      </RichTextArticle>
    )
  );
};

ServizioCosE.propTypes = {
  content: PropTypes.shape({
    descrizione_estesa: PropTypes.object,
    come_si_svolge: PropTypes.object,
  }),
};
export default ServizioCosE;
