import React from 'react';
import cx from 'classnames';
import { defineMessages } from 'react-intl';
import {
  FormGroup,
  Card,
  Form,
  Input,
  Label,
} from 'design-react-kit/dist/design-react-kit';
import { URLUtils } from '@plone/volto/helpers';
import { FormHeader } from 'volto-feedback';

const messages = defineMessages({
  header_email: {
    id: 'feedback_header_email',
    defaultMessage:
      'Inserisci un indirizzo e-mail attraverso il quale possiamo contattarti',
  },
  infotext_invalid: {
    id: 'feedback_email_infotext_invalid',
    defaultMessage: "L'indirizzo email che hai inserito non è valido",
  },
  privacy_label: {
    id: 'feedback_privacy_label',
    defaultMessage:
      'Autorizzo l’utilizzo della mia email da parte dell’Ufficio relazioni con il pubblico per l’eventuale gestione della richiesta',
  },
});

const EmailStep = ({
  updateFormData,
  userFeedback,
  positive,
  step,
  totalSteps,
  getFormFieldValue,
  intl,
}) => {
  // const invalid = !URLUtils.isMail(getFormFieldValue('email'));
  // console.log(URLUtils.isMail('giulia@test.it'));
  const invalid = false;

  return (
    <fieldset
      className={cx('email-step', {
        'd-none': step !== 2,
      })}
      data-step={step}
      aria-expanded={step === 2}
      aria-hidden={step !== 2}
    >
      <FormHeader
        title={intl.formatMessage(messages.header_email)}
        step={step + 1}
        totalSteps={totalSteps}
        className="email-header d-flex justify-content-between align-items-center"
        hidden={step !== 2}
      />
      <div className="email w-100">
        <Card teaser noWrapper>
          <Form>
            <FormGroup key={`step-${step}-email`}>
              <Input
                id="email"
                label="E-mail *"
                type="email"
                placeholder="example@email.com"
                onChange={(e) => {
                  updateFormData('email', e.target.value ?? '');
                }}
                value={getFormFieldValue('email') ?? ''}
                infoText={
                  invalid
                    ? intl.formatMessage(messages.infotext_invalid)
                    : undefined
                }
                tabIndex="0"
                autoFocus={step === 2}
                aria-invalid={invalid ? invalid : undefined}
                invalid={invalid ? invalid : undefined}
                className="mt-1"
                data-element="feedback-input-email"
              />
            </FormGroup>
            <FormGroup check key={`step-${step}-privacy`}>
              <Input
                id="privacy"
                name="privacy"
                type="checkbox"
                checked={!!getFormFieldValue('privacy')}
                onChange={(e) => {
                  console.log(e.target);
                  updateFormData('privacy', e.target.checked);
                }}
                required={true}
              />
              <Label for="privacy" check>
                {intl.formatMessage(messages.privacy_label)} *
              </Label>
            </FormGroup>
          </Form>
        </Card>
      </div>
    </fieldset>
  );
};

export default EmailStep;
