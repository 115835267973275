import React from 'react';
//eslint-disable-next-line import/no-unresolved
import logo from '~/components/Layout/img/logo.png?width=82';
// eslint-disable-next-line import/no-unresolved
import logoIntranet from '~/components/Layout/img/logo-white.png?width=82';
import config from '@plone/volto/registry';

const Logo = () => (
  <figure className="icon">
    <img
      src={config?.settings?.isIntranet ? logoIntranet : logo}
      width="82"
      height="84"
      alt="Stemma Servizio Sanitario Regionale Emilia-Romagna"
    />
  </figure>
);

export default Logo;
