import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Attachments } from '@italia/components/ItaliaTheme/View';
import {
  RichTextArticle,
  GenericCard,
} from '@italia/components/ItaliaTheme/View';

const messages = defineMessages({
  allegati: {
    id: 'uo_allegati',
    defaultMessage: 'Allegati',
  },
  documenti: {
    id: 'uo_documenti',
    defaultMessage: 'Documenti',
  },
});

const UODocuments = ({ content }) => {
  const intl = useIntl();

  return (
    <>
      {/** ALLEGATI **/}
      <Attachments
        title={intl.formatMessage(messages.allegati)}
        content={content}
        folder_name={'allegati'}
      />

      {/** DOCUMENTI CORRELATI **/}
      {content.altri_documenti?.length > 0 && (
        <RichTextArticle
          tag_id="documents_items"
          title={intl.formatMessage(messages.documenti)}
        >
          <div className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal">
            {content.altri_documenti.map((item, i) => (
              <GenericCard
                show_icon={'it-files'}
                key={item['@id']}
                item={item}
                showimage={false}
              />
            ))}
          </div>
        </RichTextArticle>
      )}
    </>
  );
};

export default UODocuments;
