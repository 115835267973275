import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import RelatedArticles from './RelatedArticlesServicesUO';

/**
 * UOServices component class.
 * @function UOServices
 * -
 * CUSTOMIZATION:
 * - showimage={false} to RelatedArticles
 * - servizi_offerti defaultMessage
 * - RelatedItems path changed for further customizations - see ./relatedArticlesServicesUO for more
 */

/**
 * important note:
 * AUSL-PC have a customization to defaultMessage servizi_offerti. If this component is removed,
 * this message need to be re-done inside overrideTranslations
 * src/components/View/UOView/OverrideTranslations.jsx
 * */

const messages = defineMessages({
  servizi_offerti: {
    id: 'servizi_offerti',
    defaultMessage: 'Prestazioni',
  },
});

const UOServices = ({ content }) => {
  const intl = useIntl();

  return content?.servizi_offerti?.length > 0 ? (
    <RelatedArticles
      id="related-services"
      items={content.servizi_offerti}
      title={intl.formatMessage(messages.servizi_offerti)}
      showimage={false}
    />
  ) : (
    <></>
  );
};

export default UOServices;
