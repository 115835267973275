/*customizations:
- aggiunto l'attributo 'positive' per sapere se è un voto positivo o negativo, e mostrare un header diverso a seconda del voto
- aggiunto l'attributo 'commentRequired' per capire se il commento è obbliggatorio
*/
import React from 'react';
import cx from 'classnames';
import { defineMessages } from 'react-intl';
import { FormGroup, Card, Form } from 'design-react-kit/dist/design-react-kit';

import { FormHeader } from 'volto-feedback';

const messages = defineMessages({
  suggestions_placeholder: {
    id: 'feedback_form_suggestions_placeholder',
    defaultMessage:
      'Explain us why, and help us improve the quality of the site',
  },
  write_here: {
    id: 'feedback_form_write_here',
    defaultMessage: 'Scrivi qui',
  },
  header_comments_positive: {
    id: 'feedback_comments_header_positive',
    defaultMessage:
      'Vuoi raccontarci di più sulla tua esperienza positiva sul nostro sito?',
  },
  header_comments_other_positive: {
    id: 'feedback_comments_header_other_positive',
    defaultMessage:
      'Ti chiediamo a chi è rivolto il tuo <strong>apprezzamento</strong> o di quali <strong>informazioni</strong> necessiti',
  },
  header_comments_negative: {
    id: 'feedback_comments_header_negative',
    defaultMessage:
      'Ti chiediamo quali difficoltà hai incontrato <strong>in questa pagina</strong>',
  },
  header_comments_other_negative: {
    id: 'feedback_comments_header_other_negative',
    defaultMessage:
      'Ti chiediamo quali difficoltà <strong>organizzative</strong> hai incontrato o di quali <strong>informazioni</strong> necessiti',
  },
  infotext_valid: {
    id: 'feedback_comments_infotext_valid',
    defaultMessage: 'Add your comment, maximum length is 200 characters',
  },
  infotext_invalid: {
    id: 'feedback_comments_infotext_invalid',
    defaultMessage: 'Comment is too long, maximum length is 200 characters',
  },
  label_comment: {
    id: 'feedback_comments_label_comment',
    defaultMessage: 'Comment',
  },
});

const CommentsStep = ({
  updateFormData,
  userFeedback,
  positive,
  step,
  totalSteps,
  getFormFieldValue,
  commentRequired,
  intl,
}) => {
  const handleChange = ({ target }) => {
    updateFormData('comment', target.value ?? '');
  };
  const invalid = getFormFieldValue('comment')?.length > 200;
  const isRequired = commentRequired();
  const answer = getFormFieldValue('answer');

  let title = positive
    ? intl.formatMessage(messages.header_comments_positive)
    : intl.formatMessage(messages.header_comments_negative);
  let textarea_placeholder = intl.formatMessage(
    messages.suggestions_placeholder,
  );
  switch (answer) {
    case 'other_negative':
      title = intl.formatMessage(messages.header_comments_other_negative);
      textarea_placeholder = intl.formatMessage(messages.write_here);
      break;
    case 'other_positive':
      title = intl.formatMessage(messages.header_comments_other_positive);
      textarea_placeholder = intl.formatMessage(messages.write_here);
      break;
    default:
      break;
  }

  return (
    <fieldset
      className={cx('comments-step', {
        'd-none': step !== 1,
      })}
      data-step={step}
      aria-expanded={step === 1}
      aria-hidden={step !== 1}
    >
      <FormHeader
        title={title}
        step={step + 1}
        totalSteps={totalSteps}
        className="comments-header d-flex justify-content-between align-items-center"
        hidden={step !== 1}
      />
      <div className="comment w-100">
        <Card teaser noWrapper>
          <Form>
            <FormGroup key={`step-${step}-comment`}>
              {/* NB: La Texarea non c'è nel vecchio kit di react
                <TextArea
                placeholder={intl.formatMessage(
                  messages.suggestions_placeholder,
                )}
                onChange={handleChange}
                rows={3}
                value={getFormFieldValue('comment')}
                infoText={
                  !invalid
                    ? intl.formatMessage(messages.infotext_valid)
                    : intl.formatMessage(messages.infotext_invalid)
                }
                tabIndex="0"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={step === 1}
                id="comment"
                label={intl.formatMessage(messages.label_comment)}
                aria-invalid={invalid ? invalid : undefined}
                invalid={invalid ? invalid : undefined}
                className="mt-1"
                data-element="feedback-input-text"
              /> */}

              <textarea
                placeholder={textarea_placeholder}
                onChange={handleChange}
                value={getFormFieldValue('comment')}
                tabIndex="0"
                rows={3}
                className="mt-1"
                data-element="feedback-input-text"
                autoFocus={step === 1}
                id="comment"
                aria-invalid={invalid ? invalid : undefined}
                invalid={invalid ? invalid : undefined}
                required={isRequired}
              />
              <label for="comment" class="active">
                {intl.formatMessage(messages.label_comment)}{' '}
                {isRequired ? '* ' : ''}
              </label>
              <small id="commentDescription" class="form-text">
                {!invalid
                  ? intl.formatMessage(messages.infotext_valid)
                  : intl.formatMessage(messages.infotext_invalid)}
              </small>
            </FormGroup>
          </Form>
        </Card>
      </div>
    </fieldset>
  );
};

export default CommentsStep;
