import React from 'react';
import PropTypes from 'prop-types';

import { defineMessages, useIntl } from 'react-intl';
import {
  richTextHasContent,
  RichText,
} from '@italia/components/ItaliaTheme/View';

const messages = defineMessages({
  presentazione_uo: {
    id: 'presentazione_uo',
    defaultMessage: 'Presentazione',
  },
});

const UOPresentation = ({ content }) => {
  const intl = useIntl();

  return richTextHasContent(content?.presentazione) ? (
    <article id="presentazione" className="it-page-section anchor-offset mt-5">
      <h4 id="header-presentazione" className="mb-3">
        {intl.formatMessage(messages.presentazione_uo)}
      </h4>
      <div className="mb-5 mt-3">
        <RichText content={content.presentazione} />
      </div>
    </article>
  ) : null;
};

export default UOPresentation;

UOPresentation.propTypes = {
  content: PropTypes.object,
};
