import { defineMessages } from 'react-intl';

import { addSchemaField } from 'design-comuni-plone-theme/config/Blocks/ListingOptions';
import { addDefaultOptions } from 'design-comuni-plone-theme/config/Blocks/ListingOptions';

const messages = defineMessages({
  show_time: {
    id: 'show_time',
    defaultMessage: 'Nascondere colonna orario',
  },
});

export const addInformationsTableTemplateOptions = (schema, formData, intl) => {
  let pos = addDefaultOptions(schema, formData, intl);

  addSchemaField(
    schema,
    'show_time_col',
    intl.formatMessage(messages.show_time),
    null,
    { type: 'boolean' },
    pos,
  );
  pos++;

  return pos;
};
