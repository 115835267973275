import React from 'react';
import { ArrayWidget } from '@plone/volto/components';
import { MultilingualWidget } from 'volto-multilingual-widget';

const addItaliaWidgets = (config) => {
  return {
    id: {
      ...config.widgets.id,
      tipologie_prestazione: MultilingualWidget(
        (props) => <ArrayWidget {...props} creatable={true} wrapped={false} />,
        [],
      ),
    },
  };
};

export default addItaliaWidgets;
