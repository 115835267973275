/* Customization:
- Updated titles with macrocategoria Bando variation
- Added CIG and CUP fields
*/

import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import {
  RichText,
  RichTextArticle,
  richTextHasContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  descrizione: {
    id: 'descrizione',
    defaultMessage: 'Descrizione',
  },
  tipologia_bando: {
    id: 'tipologia_bando',
    defaultMessage: 'Tipologia del bando',
  },
  tipologia_avviso: {
    id: 'tipologia_avviso',
    defaultMessage: 'Tipologia di avviso',
  },
  tipologia_concorso: {
    id: 'tipologia_concorso',
    defaultMessage: 'Tipologia di concorso',
  },
  destinatari_bando: {
    id: 'destinatari_bando',
    defaultMessage: 'Destinatari del bando',
  },
  destinatari_avviso: {
    id: 'destinatari_avviso',
    defaultMessage: "Destinatari dell'avviso",
  },
  destinatari_concorso: {
    id: 'destinatari_concorso',
    defaultMessage: 'Destinatari del concorso',
  },
  ente: {
    id: 'bando_ente',
    defaultMessage: 'Ente erogatore',
  },
  code_cig: {
    id: 'code_cig',
    defaultMessage: 'Codice CIG',
  },
  code_cup: {
    id: 'code_cup',
    defaultMessage: 'Codice CUP',
  },
});

const BandoText = ({ content }) => {
  const intl = useIntl();
  return richTextHasContent(content?.text) ||
    content?.tipologia_bando ||
    content?.destinatari?.length > 0 ||
    content?.ente_bando?.length > 0 ? (
    <RichTextArticle
      tag_id={'text-body'}
      title={intl.formatMessage(messages.descrizione)}
      show_title={true}
    >
      {/* DESCRIZIONE DEL BANDO */}
      {richTextHasContent(content?.text) && (
        <RichText title_size="h5" title={''} content={content?.text} />
      )}

      {/* TIPOLOGIA DEL BANDO */}
      {content?.tipologia_bando && (
        <>
          <h5>
            {content.macro_categoria?.token === 'Avviso'
              ? intl.formatMessage(messages.tipologia_avviso)
              : content.macro_categoria?.token === 'Concorso'
                ? intl.formatMessage(messages.tipologia_concorso)
                : intl.formatMessage(messages.tipologia_bando)}
          </h5>
          <span>{content.tipologia_bando.title}</span>
        </>
      )}

      {/* CODICE CIG */}
      {content?.cig?.length > 0 && (
        <>
          <h5>{intl.formatMessage(messages.code_cig)}:&nbsp;</h5>
          {content.cig instanceof Array ? (
            content.cig.map((r, i) => (
              <>
                <span dangerouslySetInnerHTML={{ __html: r }} />
                {i < content.cig.length - 1 ? ', ' : ''}
              </>
            ))
          ) : (
            <span dangerouslySetInnerHTML={{ __html: content.cig }} />
          )}
        </>
      )}

      {/* CODICE CUP */}
      {content?.cup?.length > 0 && (
        <>
          <h5>{intl.formatMessage(messages.code_cup)}:&nbsp;</h5>
          {content.cup instanceof Array ? (
            content.cup.map((r, i) => (
              <>
                <span dangerouslySetInnerHTML={{ __html: r }} />
                {i < content.cup.length - 1 ? ', ' : ''}
              </>
            ))
          ) : (
            <span dangerouslySetInnerHTML={{ __html: content.cup }} />
          )}
        </>
      )}

      {/* DESTINATARI DEL BANDO */}
      {content?.destinatari?.length > 0 && (
        <>
          <h5>
            {content.macro_categoria?.token === 'Avviso'
              ? intl.formatMessage(messages.destinatari_avviso)
              : content.macro_categoria?.token === 'Concorso'
                ? intl.formatMessage(messages.destinatari_concorso)
                : intl.formatMessage(messages.destinatari_bando)}
          </h5>
          {content.destinatari.map((item, i) => (
            <p key={'destinatari-' + i}>{item.title}</p>
          ))}
        </>
      )}
      {/* ENTE DEL BANDO */}
      {content?.ente_bando?.length > 0 && (
        <>
          <h5>{intl.formatMessage(messages.ente)}</h5>
          {content.ente_bando.map((item, i) => (
            <span key={'ente_' + i}>
              {item}
              {i < content.ente_bando.length - 1 ? ', ' : ''}
            </span>
          ))}
        </>
      )}
    </RichTextArticle>
  ) : (
    <></>
  );
};

BandoText.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
    tipologia_bando: PropTypes.shape({
      title: PropTypes.string,
      token: PropTypes.string,
    }),
    ente_bando: PropTypes.arrayOf(PropTypes.string),
    destinatari: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        token: PropTypes.string,
      }),
    ),
  }).isRequired,
};
export default BandoText;
