import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Chip, ChipLabel } from 'design-react-kit/dist/design-react-kit';

const messages = defineMessages({
  responsabili: {
    id: 'responsabili',
    defaultMessage: 'Responsabili',
  },
  responsabile_1: {
    id: 'responsabile_1',
    defaultMessage: 'Responsabile 1',
  },
  responsabile_2: {
    id: 'responsabile_2',
    defaultMessage: 'Responsabile 2',
  },
  responsabile_3: {
    id: 'responsabile_3',
    defaultMessage: 'Responsabile 3',
  },
  responsabile_4: {
    id: 'responsabile_4',
    defaultMessage: 'Responsabile 4',
  },
});

const UOManagers = ({ content }) => {
  const intl = useIntl();

  const mapResponsabili = (responsabili, title, defaultTitle) => {
    return responsabili?.length ? (
      <>
        <h5 className="title-group">{title ? title : defaultTitle}</h5>
        {responsabili?.length > 0
          ? responsabili.sort((a, b) => { return a.title.localeCompare(b.title) }).map((item) => (
            <Link
              to={flattenToAppURL(item['@id'])}
              key={item['@id']}
              title={item.title}
              className="text-decoration-none mr-2"
            >
              <Chip
                color="primary"
                disabled={false}
                large={false}
                simple
                tag="div"
              >
                <ChipLabel tag="span">{item.title}</ChipLabel>
              </Chip>
            </Link>
          ))
          : null}
      </>
    ) : (
      <></>
    );
  };

  return content?.responsabili_1?.length > 0 ||
    content?.responsabili_2?.length > 0 ||
    content?.responsabili_3?.length > 0 ||
    content?.responsabili_4?.length > 0 ? (
    <div className="responsabili">
      {/*<h5>{intl.formatMessage(messages.responsabili)}</h5>*/}
      {mapResponsabili(
        content?.responsabili_1,
        content?.responsabili_1_title?.title,
        intl.formatMessage(messages.responsabile_1),
      )}
      {mapResponsabili(
        content?.responsabili_2,
        content?.responsabili_2_title?.title,
        intl.formatMessage(messages.responsabile_2),
      )}
      {mapResponsabili(
        content?.responsabili_3,
        content?.responsabili_3_title?.title,
        intl.formatMessage(messages.responsabile_3),
      )}
      {mapResponsabili(
        content?.responsabili_4,
        content?.responsabili_4_title?.title,
        intl.formatMessage(messages.responsabile_4),
      )}
    </div>
  ) : (
    <></>
  );
};

export default UOManagers;

UOManagers.propTypes = {
  content: PropTypes.object,
};
