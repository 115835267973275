import React from 'react';
import PropTypes from 'prop-types';

import { defineMessages, useIntl } from 'react-intl';
import { ContactLink } from '@italia/components/ItaliaTheme/View';

/**
 * UOTelephones component class.
 * @function UOTelephones
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 * -
 * CUSTOMIZATION:
 * - hidden Telefoni riservati
 */

const messages = defineMessages({
  telefono_sede: {
    id: 'telefono_sede',
    defaultMessage: 'Telefoni per il pubblico',
  },
  telefoni_riservati: {
    id: 'telefoni_riservati',
    defaultMessage: 'Telefoni riservati',
  },
});

const UOTelephones = ({ content }) => {
  const intl = useIntl();

  return content?.telefono?.length > 0 ||
    content?.telefoni_riservati?.length > 0 ? (
    <div className="text-serif contatti">
      {content.telefono?.length > 0 && (
        <div className="phones-wrapper">
          <div className="label">
            <strong>{intl.formatMessage(messages.telefono_sede)}: </strong>
          </div>
          <div>
            {content.telefono.map((tel, index) => (
              <span key={'tel' + index}>
                <ContactLink tel={tel} label={false} />
                {index !== content.telefono.length - 1 && <br />}
              </span>
            ))}
          </div>
        </div>
      )}
      {content.telefoni_riservati?.length > 0 && (
        <div className="phones-wrapper">
          <div className="label">
            <strong>{intl.formatMessage(messages.telefoni_riservati)}: </strong>
          </div>
          <div>
            {content.telefoni_riservati.map((tel, index) => (
              <span key={'telr' + index}>
                <ContactLink tel={tel} label={false} />
                {index !== content.telefoni_riservati.length - 1 && <br />}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default UOTelephones;

UOTelephones.propTypes = {
  content: PropTypes.object,
};
