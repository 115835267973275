import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

/**
 * Progetto icon component class.
 * @function ProgettoIcon
 * @params {object} Dates: object.
 * @returns {string} Markup of the component.
 */
const ProgettoIcon = ({ icon }) => {
  return icon ? (
    <div className="icon-progetto-container mb-2">
      <Icon icon={icon} />
    </div>
  ) : null;
};

export default ProgettoIcon;

ProgettoIcon.propTypes = {
  icon: PropTypes.string,
};
