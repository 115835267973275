import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';

import {
  RichTextArticle,
  richTextHasContent,
} from '@italia/components/ItaliaTheme/View';

/* customizzazione:
    - aggiunto campo tipologia_prestazione
*/

const messages = defineMessages({
  service_not_active: {
    id: 'service_not_active',
    defaultMessage: 'La prestazione non è attiva',
  },
  service_typology: {
    id: 'service_typology',
    defaultMessage: 'Tipologia di prestazione',
  },
});

const ServizioStato = ({ content }) => {
  const intl = useIntl();
  return (
    <>
      {content.stato_servizio &&
        richTextHasContent(content.motivo_stato_servizio) && (
          <RichTextArticle
            content={content.motivo_stato_servizio}
            field="motivo_stato_servizio"
            tag_id={'text-motivo-stato-servizio'}
            title={intl.formatMessage(messages.service_not_active)}
          />
        )}
      {content.tipologia_prestazione?.title && (
        <RichTextArticle
          tag_id="service_typology"
          title={intl.formatMessage(messages.service_typology)}
        >
          <p>{content.tipologia_prestazione.title}</p>
        </RichTextArticle>
      )}
    </>
  );
};

ServizioStato.propTypes = {
  content: PropTypes.shape({
    stato_servizio: PropTypes.bool,
    motivo_stato_servizio: PropTypes.shape({
      data: PropTypes.string,
    }),
    tipologia_prestazione: PropTypes.object,
  }),
};
export default ServizioStato;
