import React from 'react';
import cx from 'classnames';
import { useIntl, defineMessages } from 'react-intl';
import { getSiteProperty } from '@italia/helpers';

const messages = defineMessages({
  ssn: { id: 'brand_text_ssn', defaultMessage: 'SERVIZIO SANITARIO REGIONALE' },
});

const BrandText = ({ mobile = false, subsite }) => {
  const intl = useIntl();
  return (
    <div className="it-brand-text">
      <h2>
        {intl.formatMessage(messages.ssn)}
        <div className="second-title">Emilia-Romagna</div>
      </h2>
      <h3>{subsite?.title || getSiteProperty('siteTitle', intl.locale)}</h3>

      {/*
      <h4 className={cx('no_toc', { 'd-none d-md-block': !mobile })}>
        {subsite?.description || getSiteProperty('siteSubtitle', intl.locale)}
      </h4> */}
    </div>
  );
};

export default BrandText;
