import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Input,
} from 'design-react-kit/dist/design-react-kit';
import cx from 'classnames';

const InformationsTableTemplateSkeleton = ({
  title,
  description,
  isEditMode,
  linkHref,
  show_block_bg,
}) => {
  return (
    <div
      className={cx('informazioni-table-template', {
        'public-ui': isEditMode,
      })}
    >
      <Container className="px-4 px-md-0">
        <div className="skeleton-template">
          {title && (
            <Row>
              <Col>
                <h2 className="mb-4">{title}</h2>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={6} className="offset-md-6">
              <Input id="search" label={''} type="text" className="m-2" />
            </Col>
          </Row>

          <table className="table table-striped table-bordered table-sm">
            <thead>
              <tr className="table-primary">
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {[0, 1, 2, 3, 4].map((i) => (
                <tr key={'collaboration' + i}>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              ))}
            </tbody>
          </table>

          {linkHref && <div className="link-more text-center my-4"></div>}
        </div>
      </Container>
    </div>
  );
};

InformationsTableTemplateSkeleton.propTypes = {
  linkHref: PropTypes.any,
  isEditMode: PropTypes.bool,
  title: PropTypes.string,
};

export default InformationsTableTemplateSkeleton;
