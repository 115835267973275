import React from 'react';
import { Container, Button } from 'design-react-kit/dist/design-react-kit';
import { useIntl, defineMessages } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import img from '@package/components/Layout/img/help-block.png';

const messages = defineMessages({
  help_title: {
    id: 'helpBlock_title',
    defaultMessage: 'Non hai trovato quello che cercavi?',
  },
  help_description: {
    id: 'helpBlock_description',
    defaultMessage:
      'Segnala un problema e aiutaci a migliorare il servizio, oppure parla con noi.',
  },
  help_segnala: {
    id: 'helpBlock_segnala',
    defaultMessage: 'Segnala un problema',
  },
  help_parla_con_noi: {
    id: 'helpBlock_parla_con_noi',
    defaultMessage: 'Parla con noi',
  },
});
const View = ({ data, pathname }) => {
  //const isCmsUI = pathname ? isCmsUi(pathname) : false
  const intl = useIntl();

  const links = {
    it: {
      segnala:
        '/it/orientamento-e-contatti/ufficio-relazioni-con-il-pubblico/scrivi-a-azienda',
      parla_con_noi: '/it/orientamento-e-contatti',
    },
    en: {
      segnala:
        '/it/orientamento-e-contatti/ufficio-relazioni-con-il-pubblico/scrivi-a-azienda',
      parla_con_noi: '/it/orientamento-e-contatti',
    },
  };

  return (
    <div className="block helpBlock">
      <div className="full-width">
        <div
          className="background"
          style={{ backgroundImage: `url(${img})` }}
        />
        <Container className="px-4">
          <h2>{intl.formatMessage(messages.help_title)}</h2>
          <div className="description">
            {intl.formatMessage(messages.help_description)}
          </div>
          <div className="buttons">
            <Button
              color="light"
              outline
              size="lg"
              className="my-3 mx-4"
              as={UniversalLink}
              href={links[intl.locale].segnala}
            >
              {intl.formatMessage(messages.help_segnala)}
            </Button>
            <Button
              color="light"
              size="lg"
              className="my-3 mx-4"
              as={UniversalLink}
              href={links[intl.locale].parla_con_noi}
            >
              {intl.formatMessage(messages.help_parla_con_noi)}
            </Button>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default View;
