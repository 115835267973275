import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';

import {
  RichText,
  RichTextArticle,
  richTextHasContent,
} from '@italia/components/ItaliaTheme/View';

/* customizzazione:
    - aggiunto campo durata
*/

const messages = defineMessages({
  tempi_e_scadenze: {
    id: 'tempi_e_scadenze',
    defaultMessage: 'Tempi e scadenze',
  },
  durata: {
    id: 'durata',
    defaultMessage: 'Durata',
  },
});

const ServizioTempiScadenze = ({ content }) => {
  const intl = useIntl();
  return (
    <RichTextArticle
      content={content.tempi_e_scadenze}
      add_class="style_ol_list"
      tag_id={'text-tempi_e_scadenze'}
      title={intl.formatMessage(messages.tempi_e_scadenze)}
    >
      {richTextHasContent(content.durata) && (
        <RichText
          title={intl.formatMessage(messages.durata)}
          title_size="h5"
          content={content.durata}
        />
      )}
    </RichTextArticle>
  );
};

ServizioTempiScadenze.propTypes = {
  content: PropTypes.shape({
    tempi_e_scadenze: PropTypes.shape({
      data: PropTypes.string,
    }),
    durata: PropTypes.object,
  }),
};
export default ServizioTempiScadenze;
