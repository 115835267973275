import React from 'react';
import PropTypes from 'prop-types';

import { defineMessages, useIntl } from 'react-intl';
import { ContactLink } from '@italia/components/ItaliaTheme/View';

/**
 * PersonaTelephones component class.
 * @function PersonaTelephones
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */

const messages = defineMessages({
  telefono: {
    id: 'telefono',
    defaultMessage: 'Telefono',
  },
  telefoni_riservati: {
    id: 'telefoni_riservati',
    defaultMessage: 'Numeri di telefono riservati',
  },
});

const PersonaTelephones = ({ content }) => {
  const intl = useIntl();
  const nItems = content?.telefono?.length;

  return nItems > 0 || content?.telefoni_riservati?.length > 0 ? (
    <div>
      {nItems > 0 && (
        <div className="phones-wrapper">
          <div className="label">
            <strong>{intl.formatMessage(messages.telefono)}: </strong>
          </div>
          <div className="text-serif">
            {content.telefono.map((tel, index) => (
              <>
                <ContactLink tel={tel} label={false} />
                {index !== nItems - 1 && <br />}
              </>
            ))}
          </div>
        </div>
      )}

      {content.telefoni_riservati?.length > 0 && (
        <div className="phones-wrapper">
          <div className="label">
            <strong>{intl.formatMessage(messages.telefoni_riservati)}: </strong>
          </div>
          <div className="text-serif">
            {content.telefoni_riservati.map((tel, index) => (
              <>
                <ContactLink tel={tel} label={false} />
                {index !== content.telefoni_riservati.length - 1 && <br />}
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default PersonaTelephones;

PersonaTelephones.propTypes = {
  content: PropTypes.object,
};
