import React, { createRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { GenericCard } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Pagination } from 'design-comuni-plone-theme/components/ItaliaTheme';

/**
 * RelateedArticles view component class.
 * @function RelatedArticles
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 *
 * CUSTOMIZATIONS
 * - IMPORTANT: This component is a copy of "RelatedArticles.jsx" and customized just for UOServices.jsx
 * - Pagination added to the cards generations content - number to show setted = 6
 */

const RelatedArticles = ({
  id = 'related-articles',
  items,
  title,
  showimage = true,
  title_size,
  show_title = true,
  noMargin = false,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentContent, setCurrentContent] = useState([]);
  const resultsRef = createRef();
  const b_size = 6;

  /* CHANGE PAGE (pagination) */
  useEffect(() => {
    if (items.length > 0) {
      const firstPageIndex = (currentPage - 1) * b_size;
      const lastPageIndex = firstPageIndex + b_size;
      setCurrentContent(items.slice(firstPageIndex, lastPageIndex));
    } else {
      setCurrentContent([]);
    }
  }, [items, currentPage, b_size]);

  function handleQueryPaginationChange(e, { activePage }) {
    resultsRef.current.scrollIntoView({ behavior: 'smooth' });
    const current = activePage?.children ?? 1;
    setCurrentPage(current);
  }

  return (
    <article
      id={id}
      className={cx('it-page-section', 'anchor-offset', { 'mt-5': !noMargin })}
      aria-labelledby={title_size !== 'h5' ? `header-${id}` : undefined}
      ref={resultsRef}
      aria-live="polite"
    >
      {title && show_title ? (
        title_size === 'h5' ? (
          <h5>{title}</h5>
        ) : (
          <h4 id={`header-${id}`}>{title}</h4>
        )
      ) : null}

      <div className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal">
        {currentContent?.map((item, i) => (
          <>
            <GenericCard
              key={item['@id']}
              item={item}
              showimage={showimage}
              image_field={'immagine'}
            />
          </>
        ))}
      </div>
      {items.length > b_size && (
        <Pagination
          activePage={currentPage}
          totalPages={Math.ceil(items.length / b_size)}
          onPageChange={handleQueryPaginationChange}
        />
      )}
    </article>
  );
};
export default RelatedArticles;

RelatedArticles.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  items: PropTypes.arrayOf(Object),
  title_size: PropTypes.string,
  show_title: PropTypes.bool,
  showimage: PropTypes.bool,
  noMargin: PropTypes.bool,
};
