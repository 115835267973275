import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';

import {
  RichText,
  RichTextArticle,
  richTextHasContent,
} from '@italia/components/ItaliaTheme/View';

/* customizzazione: aggiunti vari campi
  - controindicazioni,
  - effetti_collaterali,
  - procedure_integrative,
  - dopo_esame
  - alternative
*/

const messages = defineMessages({
  casi_particolari: {
    id: 'casi_particolari',
    defaultMessage: 'Casi particolari',
  },
  controindicazioni: {
    id: 'controindicazioni',
    defaultMessage: 'Controindicazioni',
  },
  effetti_collaterali: {
    id: 'effetti_collaterali',
    defaultMessage: 'Possibili effetti collaterali e complicazioni',
  },
  procedure_integrative: {
    id: 'procedure_integrative',
    defaultMessage: 'Procedure integrative',
  },
  dopo_esame: {
    id: 'dopo_esame',
    defaultMessage: "Dopo l'esame",
  },
  alternative: {
    id: 'alternative',
    defaultMessage: 'Alternative',
  },
});

const ServizioCasiParticolari = ({ content }) => {
  const intl = useIntl();

  const display =
    richTextHasContent(content.casi_particolari) ||
    richTextHasContent(content.controindicazioni) ||
    richTextHasContent(content.effetti_collaterali) ||
    richTextHasContent(content.procedure_integrative) ||
    richTextHasContent(content.dopo_esame) ||
    richTextHasContent(content.alternative);

  return display ? (
    <RichTextArticle
      content={content.casi_particolari}
      tag_id={'text-casi_particolari'}
      title={intl.formatMessage(messages.casi_particolari)}
    >
      {richTextHasContent(content.controindicazioni) && (
        <RichText
          title={intl.formatMessage(messages.controindicazioni)}
          title_size="h5"
          content={content.controindicazioni}
        />
      )}

      {richTextHasContent(content.effetti_collaterali) && (
        <RichText
          title={intl.formatMessage(messages.effetti_collaterali)}
          title_size="h5"
          content={content.effetti_collaterali}
        />
      )}

      {richTextHasContent(content.procedure_integrative) && (
        <RichText
          title={intl.formatMessage(messages.procedure_integrative)}
          title_size="h5"
          content={content.procedure_integrative}
        />
      )}

      {richTextHasContent(content.dopo_esame) && (
        <RichText
          title={intl.formatMessage(messages.dopo_esame)}
          title_size="h5"
          content={content.dopo_esame}
        />
      )}

      {richTextHasContent(content.alternative) && (
        <RichText
          title={intl.formatMessage(messages.alternative)}
          title_size="h5"
          content={content.alternative}
        />
      )}
    </RichTextArticle>
  ) : null;
};

ServizioCasiParticolari.propTypes = {
  content: PropTypes.shape({
    casi_particolari: PropTypes.shape({
      data: PropTypes.string,
    }),
  }),
};
export default ServizioCasiParticolari;
