/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  Container,
  Row,
  Col,
  Table,
} from 'design-react-kit/dist/design-react-kit';

// eslint-disable-next-line import/no-unresolved
import { ContactLink } from '@italia/components/ItaliaTheme/View';

// eslint-disable-next-line import/no-unresolved
import { ListingLinkMore } from '@italia/components/ItaliaTheme';
import { RichText } from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  luogo_nome: {
    id: 'luogo_nome',
    defaultMessage: 'Struttura',
  },
  luogo_nome_persone: {
    id: 'luogo_nome_persone',
    defaultMessage: 'Medico',
  },
  medici_riferimento: {
    id: 'medici_riferimento',
    defaultMessage: 'Medici di riferimento',
  },
  ruolo_medici: {
    id: 'ruolo_medici',
    defaultMessage: 'Ruolo',
  },
  indirizzo_luogo: {
    id: 'indirizzo_luogo',
    defaultMessage: 'Indirizzo',
  },
  orario_luogo: {
    id: 'orario_luogo',
    defaultMessage: 'Orario',
  },
  telefono_luogo: {
    id: 'telefono_luogo',
    defaultMessage: 'Telefoni',
  },
});

const InformationsTableTemplate = ({
  items,
  title,
  linkTitle,
  linkHref,
  show_time_col = false,
}) => {
  const intl = useIntl();

  return (
    <div className="informazioni-table-template mt-5">
      <Container className="px-4">
        {title && (
          <Row>
            <Col>
              <h2 className="mb-4">{title}</h2>
            </Col>
          </Row>
        )}
        {items[0]['@components'] ? (
          <Table striped bordered responsive size="md">
            <thead>
              <tr className="table-primary">
                {/* CT Persona = label Medico | All others CTs = label Struttura */}
                <th scope="col">
                  {items[0]['@type'] === 'Persona'
                    ? intl.formatMessage(messages.luogo_nome_persone)
                    : intl.formatMessage(messages.luogo_nome)}
                </th>

                {/* CT Persona = label Ruolo | All others CTs = label Medici di Riferimento */}
                {items[0]['@type'] !== 'Persona' ? (
                  <th scope="col">
                    {intl.formatMessage(messages.medici_riferimento)}
                  </th>
                ) : (
                  <th scope="col">
                    {intl.formatMessage(messages.ruolo_medici)}
                  </th>
                )}

                {/* INDIRIZZO : CT Persona = shows email |  All others CTs = fisical address */}
                <th scope="col">
                  {intl.formatMessage(messages.indirizzo_luogo)}
                </th>

                {/* ORARIO : Checkbox off/on sidebar */}
                {!show_time_col && (
                  <th scope="col">
                    {intl.formatMessage(messages.orario_luogo)}
                  </th>
                )}

                {/* TELEFONO - ALL*/}
                <th scope="col">
                  {intl.formatMessage(messages.telefono_luogo)}
                </th>
              </tr>
            </thead>
            <tbody>
              {(items?.length === 0 || !items) && (
                <tr>
                  <td colSpan="9" className="text-center">
                    {intl.formatMessage(messages.no_items_found)}
                  </td>
                </tr>
              )}
              {items.map((item) => {
                return (
                  item['@type'] !== 'Document' && (
                    <tr key={'informazioni' + item.UID}>
                      {/* CT Persona = label Medico | All others CTs = label Struttura */}
                      <th scope="row">
                        <UniversalLink href={flattenToAppURL(item['@id'])}>
                          {item?.title}
                        </UniversalLink>
                      </th>

                      {/* CT Persona = label Ruolo | All others CTs = label Medici di Riferimento */}
                      {items[0]['@type'] !== 'Persona' ? (
                        <td>
                          {item?.responsabili_1 ||
                          item?.responsabili_2 ||
                          item?.responsabili_3 ||
                          item?.responsabili_4 ? (
                            <ul className="list-unstyled">
                              {item?.responsabili_1 && (
                                <>
                                  {item?.responsabili_1_title?.title && (
                                    <li>
                                      <h6>{item.responsabili_1_title.title}</h6>
                                    </li>
                                  )}
                                  {item?.responsabili_1.map(
                                    (persona, index) => {
                                      return (
                                        <div
                                          key={
                                            'responsabili_1' + item.UID + index
                                          }
                                        >
                                          <li>
                                            •{' '}
                                            <UniversalLink
                                              href={flattenToAppURL(
                                                persona['@id'],
                                              )}
                                            >
                                              {persona.title}
                                            </UniversalLink>
                                          </li>
                                        </div>
                                      );
                                    },
                                  )}
                                </>
                              )}
                              {item?.responsabili_2 && (
                                <>
                                  {item?.responsabili_2_title?.title && (
                                    <li className="pt-2">
                                      <h6>{item.responsabili_2_title.title}</h6>
                                    </li>
                                  )}
                                  {item?.responsabili_2.map(
                                    (persona, index) => {
                                      return (
                                        <div
                                          key={
                                            'responsabili_2' + item.UID + index
                                          }
                                        >
                                          <li>
                                            •{' '}
                                            <UniversalLink
                                              href={flattenToAppURL(
                                                persona['@id'],
                                              )}
                                            >
                                              {persona.title}
                                            </UniversalLink>
                                          </li>
                                        </div>
                                      );
                                    },
                                  )}
                                </>
                              )}
                              {item?.responsabili_3 && (
                                <>
                                  {item?.responsabili_3_title?.title && (
                                    <li className="pt-2">
                                      <h6>{item.responsabili_3_title.title}</h6>
                                    </li>
                                  )}
                                  {item?.responsabili_3.map(
                                    (persona, index) => {
                                      return (
                                        <div
                                          key={
                                            'responsabili_3' + item.UID + index
                                          }
                                        >
                                          <li>
                                            •{' '}
                                            <UniversalLink
                                              href={flattenToAppURL(
                                                persona['@id'],
                                              )}
                                            >
                                              {persona.title}
                                            </UniversalLink>
                                          </li>
                                        </div>
                                      );
                                    },
                                  )}
                                </>
                              )}
                              {item?.responsabili_4 && (
                                <>
                                  {item?.responsabili_4_title?.title && (
                                    <li className="pt-2">
                                      <h6>{item.responsabili_4_title.title}</h6>
                                    </li>
                                  )}
                                  {item?.responsabili_4.map(
                                    (persona, index) => {
                                      return (
                                        <div
                                          key={
                                            'responsabili_4' + item.UID + index
                                          }
                                        >
                                          <li>
                                            •{' '}
                                            <UniversalLink
                                              href={flattenToAppURL(
                                                persona['@id'],
                                              )}
                                            >
                                              {persona.title}
                                            </UniversalLink>
                                          </li>
                                        </div>
                                      );
                                    },
                                  )}
                                </>
                              )}
                            </ul>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : (
                        <td>
                          <ul className="list-unstyled">
                            {item?.ruolo?.title}
                          </ul>
                        </td>
                      )}

                      {/* CT Persona = shows emails |  All others CTs = shows fisical addresses */}
                      <td>
                        {item['@type'] === 'Persona' ? (
                          item?.email.map && item?.email?.length > 0 ? (
                            <ul className="list-unstyled">
                              {item?.email.map((email, index) => {
                                return (
                                  <li key={item.UID + index + 'email'}>
                                    <UniversalLink href={'mailto:' + email}>
                                      {email}
                                    </UniversalLink>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : (
                            <></>
                          )
                        ) : item?.street ? (
                          <ul className="list-unstyled">
                            <li>{item?.street}</li>
                            {item?.city && <li>{item?.city}</li>}
                            {item?.zip_code && <li>{item?.zip_code}</li>}
                          </ul>
                        ) : (
                          <></>
                        )}
                      </td>

                      {/* ORARIO : Checkbox on/off sidebar */}
                      {!show_time_col && (
                        <td>
                          <RichText
                            serif={false}
                            content={item?.orario_pubblico}
                          />
                        </td>
                      )}

                      {/* TELEFONO - ALL*/}
                      <td>
                        {item?.telefono?.length > 0 ? (
                          <ul className="list-unstyled">
                            {item?.telefono?.map
                              ? item?.telefono.map((telefono, index) => {
                                  return (
                                    <li key={item.UID + index + 'telefono'}>
                                      <ContactLink
                                        tel={telefono}
                                        label={false}
                                      />
                                    </li>
                                  );
                                })
                              : null}
                          </ul>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  )
                );
              })}
            </tbody>
          </Table>
        ) : (
          <h5 className="p-3 text-white bg-primary">
            Attenzione: Seleziona almeno un criterio per disegnare la tabella
          </h5>
        )}

        <ListingLinkMore title={linkTitle} href={linkHref} className="my-4" />
      </Container>
    </div>
  );
};

InformationsTableTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkTitle: PropTypes.any,
  linkHref: PropTypes.any,
  show_time_col: PropTypes.bool,
  title: PropTypes.string,
};

export default InformationsTableTemplate;
