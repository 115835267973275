import React from 'react';
//eslint-disable-next-line import/no-unresolved
import logo from '~/components/Layout/img/logo.png?width=93';
import logoIntranet from '~/components/Layout/img/logo-white.png?width=82';
import config from '@plone/volto/registry';

const LogoFooter = () => (
  <figure className="icon">
    <img
      src={config?.settings?.isIntranet ? logoIntranet : logo}
      width="93"
      height="95"
      alt="Stemma Servizio Sanitario Regionale Emilia-Romagna"
    />
  </figure>
);

export default LogoFooter;
